import React from "react";
import {
	BrowserRouter as Router,
	Route, Redirect
} from "react-router-dom";
import { Wineries } from "./routes/Wineries/Wineries";
import { CreateWinery } from "./routes/Wineries/CreateWinery";
import { Login } from "./routes/Login/Login";
import { Home } from "./routes/Home/Home";
import { Zones } from "./routes/Zones/Zones";
import { CreateZone } from "./routes/Zones/CreateZone";
import { Tanks } from "./routes/Tanks/Tanks";
import { CreateTank } from "./routes/Tanks/CreateTank";
import { Toaster } from "react-hot-toast";

function App() {
	return (
	    <>
            <Toaster/>
            <Router>
                <Route path="/tanks">
                    <Tanks/>
                </Route>
                <Route path="/tank/new">
                    <CreateTank/>
                </Route>
                <Route path="/tank/edit">
                    <CreateTank/>
                </Route>
                <Route path="/zones">
                    <Zones/>
                </Route>
                <Route path="/zone/new">
                    <CreateZone/>
                </Route>
                <Route path="/zone/edit">
                    <CreateZone/>
                </Route>
                <Route path="/wineries">
                    <Wineries/>
                </Route>
                <Route path="/winery/new">
                    <CreateWinery/>
                </Route>
                <Route path="/winery/edit">
                    <CreateWinery/>
                </Route>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/index">
                    <Home/>
                </Route>
                <Route exact path="/">
                    <Redirect to="/index"/>
                </Route>
            </Router>
        </>
	)
}

export default App;
