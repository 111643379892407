import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useCreateAPI } from "../../hooks/create";
import { useUpdateAPI } from "../../hooks/update";
import { useQueryAPI } from "../../hooks/query";
import "./Wineries.css";

export function CreateWinery() {
    const isEditMode = window.location.href.includes("edit");
    const wineryId = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);

	const [{ isAuthenticated }] = useAuth();
	const [create, { isLoading: isCreateLoading }] = useCreateAPI("wineries");
    const [update, { isLoading: isUpdateLoading }] = useUpdateAPI("wineries");
    const [{ data: winery }] = useQueryAPI(`wineries/${wineryId}`);
	const history = useHistory();

	// Form data
	const [name, setName] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [cap, setCAP] = useState("");

	function isLoading() {
	    return isCreateLoading || isUpdateLoading;
    }
	function isInvalid() {
		return !name || !street || !city || !cap;
	}

	// Populate on edit mode
    useEffect(() => {
        if (isEditMode) {
            setName(winery.name);
            setStreet(winery.street);
            setCity(winery.city);
            setCAP(winery.cap);
        }
    }, [winery]);

	function onSubmit($event) {
		$event.preventDefault();
		if (isEditMode) {
            return update(wineryId, { name, street, city, cap }).then(() => {
                history.push("/wineries");
            });
        }

        create({ name, street, city, cap }).then(() => {
			history.push("/wineries");
		});
	}

	if (isAuthenticated === false) {
        return <Redirect to="/login"/>;
    }

	return (
		<div className="bg-container-2">
			<h1>{isEditMode ? "MODIFICA CANTINA" : "NUOVA CANTINA"}</h1>
			<form className="flex-center" onSubmit={onSubmit}>
				<input onChange={e => setName(e.target.value)} value={name || ""} placeholder="Nome della cantina"/>
				<input onChange={e => setStreet(e.target.value)} value={street || ""} placeholder="Indirizzo della cantina"/>
				<input onChange={e => setCity(e.target.value)} value={city || ""} placeholder="Città della cantina"/>
				<input onChange={e => setCAP(e.target.value)} value={cap || ""} placeholder="CAP della città"/>
				<button disabled={isInvalid() || isLoading()} type="submit" className="m-t-10">
					<i className={!isLoading() ? "fa fa-save" : "fa fa-spin fa-1x"}/> {!isLoading() ? 'SALVA' : 'SALVATAGGIO IN CORSO...'}
				</button>
			</form>
            <div className="flex-center">
                <a className="m-t-10" href={'/wineries'}>Torna indietro</a>
            </div>
		</div>
	)
}
