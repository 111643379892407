import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useCreateAPI } from "../../hooks/create";
import { useUpdateAPI } from "../../hooks/update";
import { useQueryAPI } from "../../hooks/query";
import "./Zones.css";
import { SKEY_WINERY_ID, useLocalStorage } from "../../hooks/storage";

export function CreateZone() {
    const isEditMode = window.location.href.includes("edit");
    const zoneId = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    const { value: wineryId } = useLocalStorage(SKEY_WINERY_ID);

    const [{ isAuthenticated }] = useAuth();
    const [create, { isLoading: isCreateLoading }] = useCreateAPI("zones");
    const [update, { isLoading: isUpdateLoading }] = useUpdateAPI("zones");
    const [{ data: zone }] = useQueryAPI(`zones/${zoneId}`);
    const history = useHistory();

    // Form data
    const [code, setCode] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [cap, setCAP] = useState("");

    function isLoading() {
        return isCreateLoading || isUpdateLoading;
    }
    function isInvalid() {
        return !code || !street || !city || !cap;
    }

    // Populate on edit mode
    useEffect(() => {
        if (isEditMode) {
            setCode(zone.code);
            setStreet(zone.street);
            setCity(zone.city);
            setCAP(zone.cap);
        }
    }, [zone]);

    function onSubmit($event) {
        $event.preventDefault();
        if (isEditMode) {
            return update(zoneId, { code, street, city, cap }).then(() => {
                history.push("/zones/" + wineryId);
            });
        }

        create({ code, street, city, cap, wineryId }).then(() => {
            history.push("/zones/" + wineryId);
        });
    }

    if (isAuthenticated === false) {
        return <Redirect to="/login"/>;
    }

	return (
		<div className="bg-container-2">
            <h1>{isEditMode ? "MODIFICA REPARTO" : "NUOVO REPARTO"}</h1>
            <form className="flex-center" onSubmit={onSubmit}>
                <input onChange={e => setCode(e.target.value)} value={code || ""} placeholder="Codice del reparto"/>
                <input onChange={e => setStreet(e.target.value)} value={street || ""} placeholder="Indirizzo del reparto"/>
                <input onChange={e => setCity(e.target.value)} value={city || ""} placeholder="Città del reparto"/>
                <input onChange={e => setCAP(e.target.value)} value={cap || ""} placeholder="CAP del reparto"/>
                <button disabled={isInvalid() || isLoading()} type="submit" className="m-t-10">
                    <i className={!isLoading() ? "fa fa-save" : "fa fa-spin fa-1x"}/> {!isLoading() ? 'SALVA' : 'SALVATAGGIO IN CORSO...'}
                </button>
            </form>
            {
                !!wineryId && (
                    <div className="flex-center">
                        <a className="m-t-10" href={'/zones/' + wineryId}>Torna indietro</a>
                    </div>
                )
            }
		</div>
	)
}
