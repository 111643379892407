import React from "react";
import { useAuth } from "../../hooks/auth";
import "./Home.css";
import Logo from "../../assets/logo.png";
import Screen1A from "../../assets/screen-1a.png";
import Screen2A from "../../assets/screen-2a.png";
import Screen5A from "../../assets/screen-5a.png";
import Screen6A from "../../assets/screen-6a.png";
import Play from "../../assets/play.png";
import Apple from "../../assets/apple.png";

export function Home() {
    const [{ jwt }] = useAuth();

    return (
        <div className="bg-container">
            <div className="container">
                <img src={Logo} alt="Logo" className="logo"/>
                <div className="description">App per il monitoraggio di cisterne di vino o mosto</div>
                {
                    !jwt
                        ? <a href="/login" className="accedi">Accedi</a>
                        : <a href="/wineries" className="accedi">Visualizza le tue cantine</a>
                }
            </div>

            <div className="grid-2">
                <div className="container-left">
                    <div className="title-box">Tutto sotto controllo.</div>
                    <div className="subtitle-box">Monitora le temperature dei tuoi serbatoi di vino o di mosto presenti nelle cantine.</div>
                    <img src={Screen1A} alt="Screenshot temperature" className="photo"/>
                </div>
                <div className="container-right">
                    <div className="title-box">Mai troppo caldo, mai troppo freddo.</div>
                    <div className="subtitle-box">Tieni sotto controllo le temperature dei tuoi serbatoi ovunque tu sia, con pochi semplici tocchi.</div>
                    <img src={Screen2A} alt="Screenshot tempo di lavoro" className="photo"/>
                </div>
            </div>

            <div className="grid-3">
                <div className="container-left">
                    <div className="title-box">Storico accurato.</div>
                    <div className="subtitle-box">Controlla l'andamento delle temperature nelle ultime 24 ore e i tempi di lavorazione del vino
                    </div>
                    <img src={Screen5A} alt="Screenshot temperatura 24 ore" className="photo-b"/>
                </div>
                <div className="container-right">
                    <div className="title-box">Disponibile ovunque.</div>
                    <div className="subtitle-box">L'app sarà presto disponibile sia su Android che su iOS.</div>
                    <a href="https://play.google.com/store/apps/details?id=it.elmod.enolog" className="button">
                        <img src={Play} alt="Scarica su Play Store" className="photo"/>
                    </a>
                    <a href="#" className="button">
                        <img src={Apple} alt="Scarica su App Store" className="photo"/>
                    </a>
                    <img src={Screen6A} alt="Screenshot login" className="photo"/>
                </div>
            </div>
        </div>
    )
}
