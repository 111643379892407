import { useState } from "react";
import { ENDPOINT, TOAST_STYLE_ERROR } from "../config";
import { toast } from "react-hot-toast";

export function useCreateAPI(path, options) {
    const [ endpoint ] = useState(ENDPOINT);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isError, setIsError ] = useState(false);

    async function create(body) {
        setIsError(false);
        setIsLoading(true);

        try {
            const url = new URL(`${endpoint}/${path}`), params = options?.params || {};
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(body),
            	headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("jwt"),
                }
            });
            if (res.status !== 201) {
                setIsError(true);
                setIsLoading(false);

                const json = await res.json();
                console.log(json);
                toast.error(json.details || "Non è stato possibile creare l'entità.", TOAST_STYLE_ERROR);
                return json;
            }

            setIsLoading(false);
        } catch (err) {
            setIsError(true);
            setIsLoading(false);
            toast.error("Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
            console.error(err);
            return err;
        }
    }

    return [create, { isLoading, isError }];
}
