import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import './Login.css';
import { ENDPOINT, TOAST_STYLE_ERROR } from "../../config";
import { toast } from "react-hot-toast";
import Vigna from "../../assets/vigna.jpg";

export function Login() {
    const [{ jwt }] = useAuth();
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ success, setSuccess ] = useState(false);

    async function handleLogin() {
        try {
            const res = await fetch(`${ENDPOINT}/auth/login`, {
                method: "POST",
                body: JSON.stringify({ email, password }),
                headers: { "Content-Type": "application/json" }
            });
            if (res.status !== 200) {
                toast.error(res.status === 401 ? "Credenziali di accesso non corrette. Riprovare." : "Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
                return;
            }

            const jwt = await res.text();
            localStorage.setItem("jwt", jwt);
            setSuccess(true);
        } catch (err) {
            toast.error("Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
        }
    }

    if (success || jwt) {
        return <Redirect to="/wineries"/>
    }

    return (
        <div className="img-container">
            <img alt="Sfondo vigna" src={Vigna} className="background-image"/>
            <div className="login-box">
                <h1>Login</h1>

                <div className="textbox">
                    <i className="fa fa-user"/>
                    <input type="text" placeholder="Username" onChange={e => setEmail(e.target.value)}/>
                </div>

                <div className="textbox">
                    <i className="fa fa-lock"/>
                    <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                </div>

                <input type="button" className="btn" value="ACCEDI" onClick={handleLogin}/>
            </div>
        </div>
    )
}
