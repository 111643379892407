export const SKEY_WINERY_ID = "ENOLOG_WINERY_ID";
export const SKEY_ZONE_ID = "ENOLOG_ZONE_ID";

export function useLocalStorage(key) {
    const store = window.localStorage;

    function setValue(value) {
        store.setItem(key, value);
    }
    function getItem(k) {
        return store.getItem(k);
    }

    return { value: getItem(key), setValue };
}
