import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useCreateAPI } from "../../hooks/create";
import { useUpdateAPI } from "../../hooks/update";
import { useQueryAPI } from "../../hooks/query";
import "./Tanks.css";
import { SKEY_ZONE_ID, useLocalStorage } from "../../hooks/storage";

export function CreateTank() {
    const isEditMode = window.location.href.includes("edit");
    const tankId = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    const { value: zoneId } = useLocalStorage(SKEY_ZONE_ID);

    const [{ isAuthenticated }] = useAuth();
    const [create, { isLoading: isCreateLoading }] = useCreateAPI("tanks");
    const [update, { isLoading: isUpdateLoading }] = useUpdateAPI("tanks");
    const [{ data: tank }] = useQueryAPI(`tanks/${tankId}`);
    const history = useHistory();

    // Form data
    const [label, setLabel] = useState("");
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("Stoccaggio");
    const [capacity, setCapacity] = useState(0);
    const [setPoint, setSetPoint] = useState(0);
    const [enableAlerts, setEnableAlerts] = useState(false);
    const [levelAlertThreshold, setLevelAlertThreshold] = useState(0);

    function isLoading() {
        return isCreateLoading || isUpdateLoading;
    }
    function isInvalid() {
        return !label || !code || !type || !capacity || !setPoint;
    }

    // Populate on edit mode
    useEffect(() => {
        if (isEditMode) {
            setLabel(tank.label);
            setCode(tank.code);
            setDescription(tank.description);
            setType(tank.type);
            setCapacity(tank.capacity);
            setSetPoint(tank.setPoint);
            setEnableAlerts(tank.enableAlerts);
            setLevelAlertThreshold(tank.levelAlertThreshold);
        }
    }, [tank]);

    function onSubmit($event) {
        $event.preventDefault();
        const body = {
            label, code, description, type, capacity, setPoint, enableAlerts, levelAlertThreshold
        };

        if (isEditMode) {
            return update(tankId, body).then(() => {
                history.push("/tanks/" + tank.zoneId);
            });
        }

        create({ ...body, zoneId }).then(() => {
            history.push("/tanks/" + zoneId);
        });
    }

    if (isAuthenticated === false) {
        return <Redirect to="/login"/>;
    }

	return (
		<div className="bg-container-2">
            <h1>{isEditMode ? "MODIFICA SERBATOIO" : "NUOVO SERBATOIO"}</h1>
            <form className="flex-center" onSubmit={onSubmit}>
                <input onChange={e => setLabel(e.target.value)} value={label || ""} placeholder="Nome del serbatoio"/>
                <input onChange={e => setCode(e.target.value)} value={code || ""} placeholder="Codice del serbatoio"/>
                <input onChange={e => setDescription(e.target.value)} value={description || ""} placeholder="Descrizione del serbatoio"/>
                <select onChange={e => setType(e.target.value)} value={type || ""}>
                    <option>Stoccaggio</option>
                    <option>Lavorazione</option>
                </select>
                <input onChange={e => setCapacity(e.target.value)} value={capacity || ""} type="number" min="0" placeholder="Capacità del serbatoio"/>
                <input onChange={e => setSetPoint(e.target.value)} value={setPoint || ""} type="number" min="0" placeholder="Set Point temperatura del serbatoio"/>
                <input onChange={e => setLevelAlertThreshold(e.target.value)} value={levelAlertThreshold || ""} type="number" min="0" placeholder="Soglia minima per attivazione allarme"/>
                <div>
                    <input type="checkbox" id="enable-alerts" name="enable-alerts" onChange={e => setEnableAlerts(e.target.checked)} checked={enableAlerts}/>
                    <label htmlFor="enable-alerts">Abilita notifiche di allarme</label>
                </div>
                <button disabled={isInvalid() || isLoading()} type="submit" className="m-t-10">
                    <i className={!isLoading() ? "fa fa-save" : "fa fa-spin fa-1x"}/> {!isLoading() ? 'SALVA' : 'SALVATAGGIO IN CORSO...'}
                </button>
            </form>
            {
                !!zoneId && (
                    <div className="flex-center">
                        <a className="m-t-10" href={'/tanks/' + zoneId}>Torna indietro</a>
                    </div>
                )
            }
		</div>
	)
}
