import moment from "moment";

export function Date({ date }) {
    function format() {
        return moment(date).format("DD/MM/YYYY HH:mm");
    }

    return (
        <>{format()}</>
    )
}
