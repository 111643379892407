import { useState, useEffect } from "react";
import { ENDPOINT, TOAST_STYLE_ERROR } from "../config";
import { toast } from "react-hot-toast";

export function useQueryAPI(path, options) {
    const [ endpoint, setEndpoint ] = useState(ENDPOINT);
    const [ data, setData ] = useState({ count: 0, rows: [] });
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isError, setIsError ] = useState(false);

    async function fetchData() {
        if (path.includes("new")) {
            return;
        }

        setIsError(false);
        setIsLoading(true);

        try {
            const url = new URL(`${endpoint}/${path}`), params = options?.params || {};
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
            const res = await fetch(url, {
            	headers: { Authorization: localStorage.getItem("jwt") }
            });
            if (res.status !== 200) {
                toast.error("Non è stato possibile recuperare le entità desiderate.", TOAST_STYLE_ERROR);
                setIsError(true);
                setIsLoading(false);
                return;
            }

            setData(await res.json());
        } catch (err) {
            toast.error("Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
            setIsError(true);
            console.error(err);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [endpoint]);

    return [{ data, isLoading, isError }, fetchData, setEndpoint]
}
