import React from "react";
import { useAuth } from "../../hooks/auth";
import { Redirect, useHistory } from "react-router-dom";
import { useQueryAPI } from "../../hooks/query";
import "./Wineries.css";
import { useRemoveAPI } from "../../hooks/remove";
import { Loading } from "../../components/Loading";
import { Date } from "../../components/Date";
import { SKEY_WINERY_ID, useLocalStorage } from "../../hooks/storage";

export function Wineries() {
    const [{ isAuthenticated, me, logout }] = useAuth();
    const [{ data, isLoading }] = useQueryAPI("wineries");
    const [remove] = useRemoveAPI("wineries");
    const { setValue: storeWineryId } = useLocalStorage(SKEY_WINERY_ID);
	const history = useHistory();

	function removeWinery(id) {
	    if (window.confirm("Sei sicuro di voler eliminare questa cantina?")) {
	        remove(id).then(() => window.location.reload());
        }
    }

    function gotoZones(id) {
	    history.push("/zones/" + id);
        storeWineryId(id);
    }

    if (isAuthenticated === false) {
        return <Redirect to="/index"/>;
    }

    return (
        <div className="bg-container-2">
            <h1>LISTA CANTINE</h1>
            <div id="welcome">Benvenuto {me?.firstName ?? ""} {me?.lastName ?? ""}</div>
            <button onClick={logout} id="logout-btn">LOGOUT</button>
            {
                isLoading ? <Loading/> : (
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Indirizzo</th>
                                    <th>Data Creazione</th>
                                    <th>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.rows.map(row => (
                                    <tr key={row.id}>
                                        <td>{row.id}</td>
                                        <td><a href="" onClick={() => gotoZones(row.id)}>{row.name}</a>
                                        </td>
                                        <td>{row.street} - {row.city} {row.cap}</td>
                                        <td><Date date={row.createdAt}/></td>
                                        <td>
                                            <button onClick={() => history.push('/winery/edit/' + row.id)} className="modifica" title="Modifica i dati della cantina">
                                                <i className="fa fa-pen"/>
                                            </button>
                                            <button onClick={() => removeWinery(row.id)} className="elimina" title="Elimina la cantina">
                                                <i className="fa fa-trash-alt"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }
            <div className="flex-center">
                <button onClick={() => history.push('/winery/new')} id="new-winery">
                    <i className="fa fa-plus"/> NUOVA CANTINA
                </button>
            </div>
        </div>
    )
}
