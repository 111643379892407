import React from "react";
import { useAuth } from "../../hooks/auth";
import { Redirect, useHistory } from "react-router-dom";
import { useQueryAPI } from "../../hooks/query";
import { useRemoveAPI } from "../../hooks/remove";
import { Loading } from "../../components/Loading";
import "./Zones.css";
import { Date } from "../../components/Date";
import { SKEY_WINERY_ID, useLocalStorage } from "../../hooks/storage";

export function Zones() {
    const wineryId = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    const { setValue: storeWineryId } = useLocalStorage(SKEY_WINERY_ID);

	const [{ isAuthenticated, logout }] = useAuth();
	const [{ data, isLoading }, fetchData] = useQueryAPI("zones", {
		params: { wineryId }
	});
    const [remove] = useRemoveAPI("zones");
	const history = useHistory();

    function removeZone(id) {
        if (window.confirm("Sei sicuro di voler eliminare questo reparto?")) {
            remove(id).then(() => fetchData());
        }
    }

    function gotoTanks(id) {
        history.push({ pathname: '/tanks/' + id });
        storeWineryId(wineryId);
    }
    function gotoNew() {
        history.push({ pathname: '/zone/new' });
        storeWineryId(wineryId);
    }
    function gotoEdit(id) {
        history.push({ pathname: '/zone/edit/' + id });
        storeWineryId(wineryId);
    }

	if (isAuthenticated === false) {
		return <Redirect to="/index"/>;
	}

    return (
        <div className="bg-container-2">
			<h1>LISTA REPARTI</h1>
	        <button onClick={logout} id='logout-btn'>LOGOUT</button>
	        {
	        	isLoading ? <Loading/> : (
	        		<div>
				        <table className="table">
					        <thead>
					            <tr>
                                    <th>ID</th>
                                    <th>Codice</th>
                                    <th>Indirizzo</th>
                                    <th>Data Creazione</th>
                                    <th>Azioni</th>
                                </tr>
					        </thead>
					        <tbody>
					        {
					        	data.rows.map(row => (
					        		<tr key={row.id}>
								        <td>{row.id}</td>
                                        <td><a href="" onClick={() => gotoTanks(row.id)}>{row.code}</a></td>
								        <td>{row.street}</td>
								        <td><Date date={row.createdAt}/></td>
                                        <td>
                                            <button onClick={() => gotoEdit(row.id)} className="modifica" title="Modifica i dati del reparto">
                                                <i className="fa fa-pen"/>
                                            </button>
                                            <button onClick={() => removeZone(row.id)} className="elimina" title="Elimina il reparto">
                                                <i className="fa fa-trash-alt"/>
                                            </button>
                                        </td>
							        </tr>
						        ))
					        }
					        </tbody>
				        </table>
			        </div>
		        )
	        }
            <div className="flex-center">
                <button onClick={() => gotoNew()} id="new-zone">
                    <i className="fa fa-plus"/> NUOVO REPARTO
                </button>
                <a className="m-t-10" href={'/wineries'}>Torna indietro</a>
            </div>
        </div>
    )
}
