import React from "react";
import { useAuth } from "../../hooks/auth";
import { Redirect, useHistory } from "react-router-dom";
import { useQueryAPI } from "../../hooks/query";
import "./Tanks.css";
import { Loading } from "../../components/Loading";
import { useRemoveAPI } from "../../hooks/remove";
import { SKEY_WINERY_ID, SKEY_ZONE_ID, useLocalStorage } from "../../hooks/storage";

export function Tanks() {
    const zoneId = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    const { setValue: storeZoneId } = useLocalStorage(SKEY_ZONE_ID);
    const { value: wineryId } = useLocalStorage(SKEY_WINERY_ID);

    const [{ isAuthenticated, logout }] = useAuth();
	const [{ data, isLoading }, fetchData] = useQueryAPI("tanks", {
		params: { zoneId, limit: 10000 }
	});
    const [remove] = useRemoveAPI("tanks");
	const history = useHistory();

    function removeTank(id) {
        if (window.confirm("Sei sicuro di voler eliminare questo serbatoio?")) {
            remove(id).then(() => fetchData());
        }
    }

    function gotoNew() {
        history.push({ pathname: '/tank/new' });
        storeZoneId(zoneId);
    }
    function gotoEdit(id) {
        history.push({ pathname: '/tank/edit/' + id });
        storeZoneId(zoneId);
    }

    if (isAuthenticated === false) {
        return <Redirect to="/index"/>;
    }

    return (
        <div className="bg-container-2">
            <h1>LISTA SERBATOI</h1>
            <button onClick={logout} id='logout-btn'>LOGOUT</button>
            {
                isLoading ? <Loading/> : (
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Codice</th>
                                    <th>Tipologia</th>
                                    <th>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.rows.map(row => (
                                    <tr key={row.id}>
                                        <td>{row.id}</td>
                                        <td>{row.label}</td>
                                        <td>{row.code}</td>
                                        <td>{row.type}</td>
                                        <td>
                                            <button onClick={() => gotoEdit(row.id)} className="modifica" title="Modifica i dati del serbatoio">
                                                <i className="fa fa-pen"/>
                                            </button>
                                            <button onClick={() => removeTank(row.id)} className="elimina" title="Elimina il serbatoio">
                                                <i className="fa fa-trash-alt"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }
            <div className="flex-center">
                <button onClick={() => gotoNew()} id="new-zone">
                    <i className="fa fa-plus"/> NUOVO SERBATOIO
                </button>
                {!!wineryId && <a className="m-t-10" href={'/zones/' + wineryId}>Torna indietro</a>}
            </div>
        </div>
    )
}
