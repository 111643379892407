import { useState, useEffect } from "react";
import { ENDPOINT, TOAST_STYLE_ERROR } from "../config";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

export function useAuth() {
    const [ endpoint, setEndpoint ] = useState(ENDPOINT);
    const [ jwt, setJWT ] = useState(getJWT());
    const [ me, setMe ] = useState(null);
    const [ isAuthenticated, setIsAuthenticated ] = useState(null);
    const location = useLocation();

    function getJWT() {
        return localStorage.getItem("jwt");
    }

    function logout() {
        localStorage.removeItem("jwt");
        setJWT(null);
        setMe(null);
        setIsAuthenticated(false);
    }

    async function fetchMe() {
        try {
            const res = await fetch(`${endpoint}/auth/me`, {
                headers: { Authorization: jwt }
            });
            if (res.status !== 200 && location.pathname && !location.pathname.includes("/login") && !location.pathname.includes("/index") && location.pathname !== "/") {
                toast.error(res.status === 401 ? "Sessione scaduta. Effettuare nuovamente l'accesso" : "Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
                return;
            }

            setMe(await res.json());
            setIsAuthenticated(true);
        } catch (err) {
            console.error(err);
            setIsAuthenticated(false);
            toast.error("Impossibile recuperare la sessione. Il server è offline o in manutenzione.", TOAST_STYLE_ERROR);
        }
    }

    useEffect(() => {
        fetchMe();
    }, []);

    return [{ jwt, me, logout, isAuthenticated }, setEndpoint];
}
